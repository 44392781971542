/* eslint-disable linebreak-style */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
// eslint-disable-next-line linebreak-style
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
// main tools
import {
  useState, useEffect, useCallback, Fragment,
} from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// react bootsrap
import { Container, Row, Col } from 'react-bootstrap';

// components
import ProductPreview from '../../components/ProductPreview';
import FavoritesMenu from '../../components/FavoritesMenu';
import ProductCard from '../../components/ProductCard';
import ChooseMenu from '../../components/ChooseMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Empty from '../../components/Empty';
import Basket from './CardBuyProducts/Basket/Basket';

// Context
import { useAuth } from '../../context/authContext';

// styles
import styles from './styles.module.css';

// services
import {
  RestaurantService, MenuService, ProductService, UserService,
} from '../../services';
import Greetings from '../../components/Greetings';

function Menus() {
  const { newLike, setNewLike } = useAuth();
  const [searchProductsByCat, setSearchProductsByCat] = useState();
  const [showFavProducts, setShowFavProducts] = useState();
  const [productsByCat, setProductsByCat] = useState();
  const [selectedMenu, setSelectedMenu] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [categoryIdx, setCategoryIdx] = useState();
  const [categories, setCategories] = useState();
  const [restaurant, setRestaurant] = useState();
  const [productIdx, setProductIdx] = useState();
  const [menus, setMenus] = useState();
  const [changeBuyProd, setChangeBuyProd] = useState(false);
  const [cart, setCartItems] = useState([]);
  const [cartHasItems, setCartHasItems] = useState(false);
  const [cartItemCounter, setCartItemCounter] = useState(0);
  const [priceData, setPriceData] = useState();
  const params = useParams();

  const handleSelectMenu = (menu) => {
    setSelectedMenu(menu);
  };
  const handleSelectProduct = (idx, index) => {
    setCategoryIdx(idx);
    setProductIdx(index);
  };
  const handleClosePreview = () => {
    setProductIdx(undefined);
    setCategoryIdx(undefined);
  };
  const handleShowChooseMenu = useCallback(() => {
    setSelectedMenu(undefined);
    setMenus(undefined);
    setCategories(undefined);
    setProductsByCat(undefined);
    setSearchProductsByCat(undefined);
    setSearchValue('');
  }, [setSelectedMenu, setMenus, setCategories, setProductsByCat]);

  const handleFavMenu = () => {
    setShowFavProducts(!showFavProducts);
  };

  const handleSearchChange = ({ target }) => {
    const { value } = target;
    const val = value.toLowerCase();

    setSearchValue(value.trimStart());

    if (value.trim() !== ''.toLowerCase) {
      const data = productsByCat.reduce((acc, nextCat) => {
        const fp = nextCat.products.filter((item) => item.name.toLowerCase().includes(val));

        if (fp.length) {
          fp.sort((a, b) => {
            if (a.positionInCategory > b.positionInCategory) return 1;
            if (a.positionInCategory < b.positionInCategory) return -1;
            return 0;
          });
          acc.push({ ...nextCat, products: fp });
        }

        return acc;
      }, []);

      setSearchProductsByCat(data);
    } else setSearchProductsByCat(undefined);
  };

  /**
   * Get buy products
   */
  useEffect(() => {
    const getDataUser = async () => {
      const phoneUser = localStorage.getItem('user');
      const [dataUser] = await UserService.getUser(phoneUser);
      setPriceData(dataUser?.buyProducts);

      if (dataUser?.buyProducts && dataUser?.buyProducts?.length !== 0) {
        setCartHasItems(true);
        setCartItems(dataUser?.buyProducts);
        const leghtProducts = dataUser?.buyProducts?.length;
        setCartItemCounter(leghtProducts);
      } else {
        setCartHasItems(false);
      }
    };
    getDataUser();
  }, [newLike, changeBuyProd, params?.id]);

  /**
   * Get restaurant and all menus data by id from url
   */
  useEffect(() => {
    (async () => {
      const [data] = await RestaurantService.getRestaurant(params?.id);

      if (data) {
        localStorage.removeItem('restaurant');
        localStorage.setItem('restaurant', params?.id);
        if (data?.fontFamily) {
          document.querySelector('body').style.fontFamily = data?.fontFamily;
        }

        const allMenus = await MenuService.getMenus(data?.id);
        const allCategories = await ProductService.getCategories(data?.id);

        setMenus(allMenus);
        setRestaurant(data);
        setCategories(allCategories);
      }
    })();
  }, [params, productsByCat]);

  /**
   * Get all menus
   */
  useEffect(() => {
    if (selectedMenu && categories) {
      (async () => {
        const data = await ProductService.getProductsByMenu(selectedMenu?.id);

        const productsByCategories = categories.reduce((value, nextItem) => {
          const products = data.filter((item) => item?.categoryId === nextItem?.id);

          products.sort((a, b) => {
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;
            return 0;
          });

          const category = { ...nextItem, products };
          if (category.products.length) value.push(category);

          return value;
        }, []);

        setProductsByCat(productsByCategories);
      })();
    }
    // eslint-disable-next-line
  }, [selectedMenu]);

  const getDataUser = async () => {
    const phoneUser = localStorage.getItem('user');
    const [dataUser] = await UserService.getUser(phoneUser);

    if (dataUser?.phone && dataUser?.phone === phoneUser) {
      if (!dataUser.buyProducts) dataUser.buyProducts = [];
      return dataUser;
    }

    return null;
  };

  const updateQuantity = async (product, id, value) => {
    const numberValue = Number(value);
    const newProd = {
      status: true, id: product.id, name: product.name, quantity: numberValue,
    };

    const dataUser = await getDataUser();
    const index = dataUser?.buyProducts.findIndex((likedProduct) => likedProduct.id === id);
    const updatedObj = { ...dataUser?.buyProducts[index], quantity: numberValue };
    const updatedArr = [...dataUser?.buyProducts.slice(0, index), updatedObj, ...dataUser?.buyProducts.slice(index + 1)];

    ProductService.updateLikedProducts({
      ...dataUser,
      buyProducts: updatedArr,
    });
    setNewLike(updatedArr);
  };

  const removeFromCart = (id) => {
    if (cartItemCounter === 0) return;
    setCartItems(cart.filter((cartItem) => cartItem.itemId !== id));
    setCartItemCounter(cartItemCounter - 1);
  };

  const getTotal = priceData?.reduce((acc, item) => acc + item.price * item.quantity, 0)?.toFixed(2);

  return (
    <>
      <Helmet>
        <title>{restaurant?.restaurantName}</title>
        <meta name="description" content={restaurant?.description} />
      </Helmet>
      <Container
        fluid
        style={{
          backgroundColor: restaurant?.shortUrl === 'la-biblioteque' ? '#1E2E5F' : 'black',
        }}
      >
        <Row className="justify-content-center px-4">
          <Col sm={9} md={8} lg={6} xs={12}>
            <div className="text-center my-4">
              <img
                src={restaurant?.logo}
                className={styles.logo}
                alt={restaurant?.restaurantName}
              />
            </div>
            <Header
              searchValue={searchValue}
              handleFavMenu={handleFavMenu}
              handleSearchChange={handleSearchChange}
              handleShowChooseMenu={handleShowChooseMenu}
            />
          </Col>
        </Row>
        <Row className="justify-content-center px-4">
          <Col sm={9} md={8} lg={6} xs={12}>
            <Greetings showLogo={false} restaurant={restaurant} />
            {productsByCat && productsByCat.length ? (
              <Row>
                {searchProductsByCat
                  ? searchProductsByCat.map((category, idx) => (
                    <Fragment key={category.id}>
                      <Col xs={12} className="my-4 d-flex">
                        <h2 className={styles.category}>{category.name}</h2>
                      </Col>
                      {category.products.map((prd, index) => (
                        <Col key={prd.id} xs={6} className="d-flex">
                          <ProductCard
                            product={prd}
                            showFavProducts={showFavProducts}
                            handleSelectProduct={() => handleSelectProduct(idx, index)}
                            changeBuyProd={changeBuyProd}
                            setChangeBuyProd={setChangeBuyProd}
                          />
                        </Col>
                      ))}
                    </Fragment>
                  ))
                  : productsByCat.map((category, idx) => (
                    <Fragment key={category.id}>
                      <Col xs={12} className="my-4 d-flex">
                        <h2 className={styles.category}>{category.name}</h2>
                      </Col>
                      {category.products.map((prd, index) => (
                        <Col key={prd.id} xs={6} className="d-flex">
                          <ProductCard
                            product={prd}
                            showFavProducts={showFavProducts}
                            handleSelectProduct={() => handleSelectProduct(idx, index)}
                          />
                        </Col>
                      ))}
                    </Fragment>
                  ))}
              </Row>
            ) : (
              <Empty title="Empty" />
            )}
            <Row>
              <Col className="my-5">
                <Footer logo={restaurant?.logo} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <FavoritesMenu onHide={handleFavMenu} restaurant={restaurant} visible={showFavProducts} />

      <ProductPreview
        productIdx={productIdx}
        restaurant={restaurant}
        categoryIdx={categoryIdx}
        visible={productIdx !== undefined}
        handleClosePreview={handleClosePreview}
        categories={searchProductsByCat ?? productsByCat}
      />

      <ChooseMenu
        menus={menus}
        restaurant={restaurant}
        visible={!selectedMenu}
        handleSelectMenu={handleSelectMenu}
      />
      {cartHasItems && (
        <Basket
          updateQuantity={updateQuantity}
          deleteItem={removeFromCart}
          cartIsShown={cartHasItems}
          itemCount={cartItemCounter}
          cart={cart}
          total={getTotal}
        />
      )}
    </>
  );
}

export default Menus;
